import React, { useEffect, useState } from "react"
import styled from "styled-components"

import ScrollReveal from "scrollreveal"

import ListItem from "./list-item"

const Container = styled.div`
  padding: 3rem 1.5rem;

  @media (min-width: 576px) {
    padding: 3rem 4rem;
  }

  .show-previous {
    max-height: 999px;
    overflow: hidden;
  }

  .hide-previous {
    max-height: 0;
    overflow: hidden;
  }
`
const Name = styled.div`
  color: white;
  padding-bottom: 1rem;
  // border-bottom: 1px solid #272727;

  h1 {
    font-weight: 300;
    color: #272727;
  }
`

const Line = styled.div`
  width: 100%;
  height: 1px;
  background-color: #272727;
`

const TourInfo = styled.div`
  padding-bottom: 1rem;

  @media (min-width: 992px) {
    max-width: 50%;
  }
`

const PreviousDates = styled.div``

const More = styled.div`
  color: #272727;
  padding: 2rem 0;

  h3 {
    font-weight: 300;
    width: fit-content;
    transition-duration: 0.7s;
    cursor: pointer;
  }

  h3:hover {
    opacity: 0.5;
    transition-duration: 0.7s;
  }
`

export default props => {
  let [next, setNext] = useState([])
  let [previous, setPrevious] = useState([])
  let [hasClickedPrevious, setHasClickedPrevious] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      ScrollReveal({
        //   reset: true,
        delay: 3,
        distance: "30px",
      }).reveal(".list-item")
    }, 500)

    return () => {
      ScrollReveal().destroy()
    }
  }, [])

  useEffect(() => {
    let date = new Date().toISOString()

    let next = props.data.events.filter(item => {
      if (item.date > date) {
        return item
      }
    })

    setNext(next)

    let previous = props.data.events.filter(item => {
      if (item.date < date) {
        return item
      }
    })

    setPrevious(previous)
  }, [])

  return (
    <Container>
      <Name>
        <h1>{props.data.title}</h1>
      </Name>
      <TourInfo dangerouslySetInnerHTML={{ __html: props.data.text }} />
      <Line />
      {next.map(item => (
        <ListItem data={item} />
      ))}
      <More onClick={() => setHasClickedPrevious(true)}>
        <h3>+ View Previous Dates</h3>
      </More>
      <PreviousDates
        className={hasClickedPrevious ? "show-previous" : "hide-previous"}
      >
        {previous.map(item => (
          <ListItem data={item} />
        ))}
      </PreviousDates>
    </Container>
  )
}
