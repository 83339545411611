import React from "react"
import styled from "styled-components"

import { Link } from "gatsby"

var moment = require("moment")

const ListItem = styled.div`
  border-bottom: 1px solid #272727;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  visibility: hidden;

  padding: 0.5rem 0;

  @media (min-width: 576px) {
    flex-direction: row;
    padding: 0;
  }
`

const Left = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  @media (max-width: 576px) {
    margin: 0.5rem 0;
  }
`

const Right = styled.div``

const Date = styled.div`
  width: 7rem;
  color: #272727;

  h3 {
    font-weight: 300 !important;
    font-family: "Roboto";
  }

  @media (min-width: 576px) {
    width: 15rem;

    h3 {
      font-size: 19px !important;
    }
  }
`

const Location = styled.div`
  color: #272727;

  h3 {
    font-weight: 300 !important;
    font-family: "Roboto";
  }

  @media (min-width: 576px) {
    h3 {
      font-size: 19px !important;
    }
  }
`

const Button = styled.a`
  padding: 0 3rem !important;
  display: flex;
  text-transform: uppercase;
  text-decoration: none;
  text-align: center;

  margin: 0.5rem 0;
  box-sizing: border-box;

  width: 100% !important;

  @media (min-width: 576px) {
    min-width: 12rem;
  }
`

export default props => {
  return (
    <ListItem className="list-item">
      <Left>
        <Date>
          <h3>{moment(props.data.date).format("D/MM/YYYY")}</h3>
        </Date>
        <Location>
          <h3>{props.data.location}</h3>
        </Location>
      </Left>
      <Right>
        <Button
          {...(props.data.active ? { href: props.data.ctaLink } : {})}
          className={
            props.data.active
              ? "button button-active"
              : "button button-inactive"
          }
          target="_blank"
        >
          {props.data.ctaText}
        </Button>
      </Right>
    </ListItem>
  )
}
